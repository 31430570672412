exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-[product]-tsx": () => import("./../../../src/pages/products/[product].tsx" /* webpackChunkName: "component---src-pages-products-[product]-tsx" */),
  "component---src-pages-products-bus-recognizer-boards-tsx": () => import("./../../../src/pages/products/bus-recognizer-boards.tsx" /* webpackChunkName: "component---src-pages-products-bus-recognizer-boards-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */)
}

